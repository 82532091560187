import React, { useEffect, useState } from 'react';
import '../css/quote.css';

export function Kanye(props) {
    const [quote, setQuote] = useState('Loading...');
    const [flipped, setFlipped] = useState(false);

    const updateQuote = () => {
        fetch('https://api.kanye.rest/').then(resp => resp.json()).then(data => {
            setQuote(data.quote)
            // disabling for now
            // playKanyeVoice(data.quote);
        });
        setFlipped(!flipped);
    }

    useEffect(() => {
        updateQuote();
    }, []);

    const playKanyeVoice = (quote) => {
        const uberDuckApiKey = "pub_wiwfzxaqawladupnnm";
        const uberDuckApiSecret = "pk_cae96f90-323a-4e86-816a-5fce180d9524";

        const headers = new Headers({
            'Authorization': 'Basic ' + btoa(`${uberDuckApiKey}:${uberDuckApiSecret}`)
        });
        // const speakUrl = 'https://api.uberduck.ai/speak';
        const speakUrl = '/speak.php';
        // get url
        fetch(speakUrl, {
            method: 'post',
            headers,
            body: JSON.stringify({
                speech: quote,
                voice: "tyler-the-creator", 
                pace: 1
            })
        }).then(resp => resp.json()).then(data => {
            const uuid = data.uuid;
            // const statusUrl = `https://api.uberduck.ai/speak-status?uuid=${uuid}`;
            const statusUrl = '/speak-status.php?uuid=' + uuid;
            let voiceUrl = null;
            let checkCount = 0;

            // call api every second to get url, timeout after 10 tries
            const interval = setInterval(() => {
                fetch(statusUrl, {
                    headers
                }).then(resp => resp.json()).then(data => {
                    console.log(data);

                    if(data.path){
                        clearInterval(interval);
                        voiceUrl = data.path;
                        // console.log(voiceUrl);
                        const voice = new Audio(voiceUrl);
                        voice.load();
                        voice.play();
                    }
                    
                    if(checkCount >= 10 || data.failed_at != null){
                        clearInterval(interval);
                        alert("voice could not be found or failed");
                    }
                });
                checkCount++;
            }, 1000);
        });
    }

    return (
        <div className='container'>
            <h4 className='header'>Click Kanye to be enlightened by TRUTH</h4>
            <center>
                <img onClick={updateQuote} src="https://www.seekpng.com/png/full/12-126395_kanye-head-png-kanye-west-transparent-background.png" alt="kanye" className={(flipped ? 'flip' : '') + ' kanye_img'}/>
            </center>
            <h1 className='quote'>{quote}</h1>
            <center>
                {/* <button onClick={updateQuote}>Kanye my ass</button> */}
            </center>
        </div>
    )
}